<template>
  <section class="about">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="top-banner"
      >
        <v-layout
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
          <v-flex style="position: absolute; bottom: 40px;" class="ctk-about-submenu">
            <template>
              <v-tabs
                v-model="tabSubMenu"
                light
                grow
              >
                <v-tab
                  v-for="(menu, k) in subMenu"
                  :key="k"
                  :href="'/service/' + menu.key"
                >
                  {{menu.name}}
                </v-tab>
              </v-tabs>
            </template>
          </v-flex>
        </v-layout>
    </v-img>
    <!--色彩之旅-->
    <v-container  grid-list-md text-center class="ctk-div">
      <v-container
        fluid
        grid-list-sm
      >
        <v-layout wrap>
          <v-flex xs12 sm12 md12 lg12 class="text-left">
            <h5 class="ctk-div-title text-center">{{service.color.title}}</h5>
            <span style="font-size:16px; color:#333;" v-html="service.color.profile">{{service.color.profile}}</span>
          </v-flex>
        </v-layout>
        <div class="text-center ctk-more-btn-div">
          <v-btn class="ma-2 ctk-more-btn" outlined color="#333" href="/service/color">{{this.$t('service.detail')}}</v-btn>
        </div>
      </v-container>
    </v-container>
    <!--企业服务-->
    <div style="background:#F8F8F8;">
      <v-container text-center class="ctk-div">
        <v-layout>
          <v-flex xs12 sm12 md6 lg6 class="text-left">
            <h5 class="ctk-div-title">{{service.service.title}}</h5>
            <span style="font-size:16px; color:#333;" v-html="service.service.profile">{{service.service.profile}}</span>
            <div class="text-left ctk-more-btn-div mt-4">
              <v-btn class="ma-2 ctk-more-btn" outlined color="#333" href="/service/detail">{{this.$t('service.detail')}}</v-btn>
            </div>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 class="text-left">
            <v-img :src="service.service.img" max-height="300"></v-img>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <!--Q&A-->
    <v-container  grid-list-md text-center class="ctk-div">
      <v-container
        fluid
        grid-list-sm
      >
        <h3 class="text-left mb-2" style="font-size:32px; color:#333;">{{this.$t('service.qaTitle')}}</h3>
        <v-layout wrap>
          <v-flex xs12 sm12 md6 lg6  class="text-left" v-for="(qa, k) in service.qa" :key="k">
            <a :href="'/service/qa/' + qa.id" style="color:#333; font-size:16px;">Q: {{qa.title}}</a>
          </v-flex>
        </v-layout>
        <div class="text-center ctk-more-btn-div mt-4">
          <v-btn class="ma-2 ctk-more-btn" outlined color="#333" href="/service/qa">{{this.$t('service.more')}}</v-btn>
        </div>
      </v-container>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 5,
    tabSubMenu: null,
    subMenu: null,
    service: {
      service: { title: null, profile: null },
      color: { title: null, profile: null },
      qa: []
    }
  }),
  created () {
    document.querySelector('#menu_service').classList.add('v-btn--active')
    this.tabSubMenu = -1
    this.getPageData()
    this.getService()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        let locale = localStorage.getItem('locale')
        if (!locale) {
          locale = 'cn'
        }
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      let locale = localStorage.getItem('locale')
      if (!locale) {
        locale = 'cn'
      }
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getPageData () {
      this.subMenu = this.$t('service.subMenu')
    },
    getService () {
      this.https.get('service').then(response => {
        if (response.code === 0) {
          this.service = response.data
        }
      })
    }
  }
}
</script>
<style>
</style>
